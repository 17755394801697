body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

.App {
   text-align: center;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
   .App-logo {
      animation: App-logo-spin infinite 20s linear;
   }
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

#components-layout-demo-top-side-2 .logo {
   float: left;
   width: 120px;
   height: 31px;
   margin: 16px 24px 16px 0;
   background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
   float: right;
   margin: 16px 0 16px 24px;
}

.site-layout-background {
   background: #fff;
}

.omg-layout {
   padding: 0 24px 24px;
   margin-left: 200px;
}

.csv-upload {
   text-align: center;
   background: #fafafa;
   border: 1px dashed #d9d9d9;
   border-radius: 2px;
   cursor: pointer;
   padding: 30px;
}

.csv-upload:hover {
   border-color: #40a9ff;
}
